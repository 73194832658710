
"use client"
import { ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd'
import React from 'react'
import CountUp from 'react-countup';

const formatter = (value) => <CountUp end={value} separator="," />

const Testimonial = () => {

  return (
    <section>
    <div style={{minHeight:600}}>
    <div  style={{display:'flex',flexDirection:'column',padding:20,minHeight:600,alignItems:'center',justifyContent:'center',gap:40,height:'100%',background:'#272f95'}}>
      <h4 style={{color:'white',textAlign:'center'}} className="big">
      What sets us apart
      </h4>
      <span style={{color:"white",textAlign:'center',fontSize:"15px",width:'80%'}}>
      Our commitment to passion, teamwork, and a customer-centric approach forms the very essence of Guton's ethos. We believe in going beyond merely providing real estate solutions; instead, we immerse ourselves in the dreams and aspirations of our customers.

With a passionate and dedicated team, we ensure that every aspect of our service is imbued with excellence and attention to detail. From the moment you engage with us to the finalization of your property transaction, we strive to exceed your expectations at every step.
        </span>
      <Row gutter={[20,20]} style={{width:'100%'}}>
      <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{display:'flex',flexDirection:'column',alignItems:'center',gap:20,justifyContent:"center"}}>
      <Card bordered={false}>
      <Statistic prefix={<ArrowUpOutlined />}  valueStyle={{color:'black'}} title="Total Assets" value={100500000} formatter={  formatter} />
      </Card>
        </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{display:'flex',flexDirection:'column',alignItems:'center',gap:20,justifyContent:"center"}}>
      <Card bordered={false}>
      <Statistic prefix={<ArrowUpOutlined />}  valueStyle={{color:'black'}} title="Land Percels Sold" value={13000} formatter={formatter} />
      </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{display:'flex',flexDirection:'column',alignItems:'center',gap:20,justifyContent:"center"}}>
        <Card bordered={false}>
        <Statistic prefix={<ArrowUpOutlined />}  valueStyle={{color:'black'}} title="Title deeds Issued" value={11000} formatter={formatter} />
        </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{display:'flex',flexDirection:'column',alignItems:'center',gap:20,justifyContent:"center"}}>
        <Card bordered={false}>
        <Statistic prefix={<ArrowUpOutlined />}  valueStyle={{color:'black'}} title="Estate Agents" value={600} formatter={formatter} />
        </Card>
        </Col>
      </Row>
    </div>
    </div>
   </section>
  )
}

export default Testimonial