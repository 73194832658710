"use client"
import { Button, Card, Col, Flex, Row, Typography } from 'antd'
import React from 'react'
import FormInput from './formModal'

const Stats = ({data}) => {
  return (
   <div style={{padding:20,marginBottom:20}}>
    <Flex vertical align='center' justify='center' style={{width:'100%',marginBottom:20,height:250}}>
    <Typography.Title level={3} style={{textAlign:'center'}}>
               What People Are Saying
            </Typography.Title>
            <Typography.Text style={{textAlign:'center',maxWidth:'80%'}}>
            At Guton, teamwork is not just a concept; it's the heartbeat of our operations. We understand the value of collaboration and leverage the individual expertise of our team members to deliver unparalleled results. By pooling together our diverse skills and experiences, we achieve success that transcends boundaries.
            </Typography.Text>
           <FormInput />
    </Flex>
     <Row gutter={[20,20]}>
    {
        data.map((item) => (
            <Col key={item.title}  xs={32} sm={32} md={6} lg={6}  xl={6}>
             <div style={{display:"flex",alignItems:'center',justifyContent:'center',flexDirection:'column',backgroundColor:'whitesmoke',padding:30,borderRadius:10}}>
            <Typography.Text style={{textAlign:'center',color:"#272f95"}}>
                {item.desc}
            </Typography.Text>
             </div>
            </Col>  
        ))
    }
  </Row>
   </div>
  )
}

export default Stats