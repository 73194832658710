"use client"
import { Button, Card, Col, Flex, Result, Row, Typography } from 'antd'
import React from 'react'
import App from '../components/card'
import Link from 'next/link'
import FormInput from './formModal'

const Stats = ({data}) => {
  return (
   <div style={{padding:20,width:"100%"}}>
    <div style={{padding:20,minHeight:100,display:'flex',gap:10,flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

    <Typography.Title level={2} style={{textAlign:'center'}}>
             Our Featured Properties
            </Typography.Title>
            <Typography.Text style={{textAlign:'center',maxWidth:"80%",fontSize:15}}>
    Our dedication to customer satisfaction compels us to guarantee that each property transaction is not only legally compliant but also accompanied by readily available title deeds. Recognizing the significance of transparency and reliability within the real estate sector, we prioritize ensuring that our customers have the assurance that their investments are secure, providing them with peace of mind.
      </Typography.Text>
    <Link href="/properties-for-sale">
    <Button>
        Browse More 
      </Button>
    </Link>
    </div>
    {
      data.length ?
     <Row gutter={[10,10]}>
  
       { data.slice(0,4).map((item) => (
       <Col key={item.id}  xs={32} sm={32} md={6} lg={6}  xl={6}>
  
           <App  data={item} />
     </Col>
     ))}
      
      </Row>
      :(
      <div style={{height:500,width:'100%',padding :20,display:'flex',alignItems:'center',justifyContent:"center"}}>
      <Result
      status="404"
      title="404"
      subTitle="Sorry, We Do Not Have What You Are Looking For."
      extra={<FormInput />}
      />
      </div>
     )
    }
   
      </div>
  )
}

export default Stats