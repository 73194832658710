"use client"
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Radio, notification } from 'antd';
import axios from 'axios';
const CollectionCreateForm = ({ initialValues, onFormInstanceReady }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    onFormInstanceReady(form);
  }, []);
  return (
    <Form layout="vertical" form={form} name="form_in_modal" initialValues={initialValues}>
      <Form.Item
        name="Name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Please input the title of collection!',
          },
        ]}
      >
        <Input size='large' placeholder='Fill In You Details' />
      </Form.Item>
      <Form.Item  rules={[
          {
            required: true,
            message: 'Please input the title of collection!',
          },
        ]} name="Email" label="Email">
        <Input size='large' placeholder='Fill In You Details' type="email" />
      </Form.Item>
      <Form.Item  rules={[
          {
            required: true,
            message: 'Please input the title of collection!',
          },
        ]} name="Phone" label="Phone">
        <Input size='large' placeholder='Fill In You Details' type="email" />
      </Form.Item>
      <Form.Item  rules={[
          {
            required: true,
            message: 'Please input the title of collection!',
          },
        ]} name="Message" label="Message">
        <Input size='large' placeholder='Fill In You Details' type="textarea" />
      </Form.Item>
    </Form>
  );
};
const CollectionCreateFormModal = ({ open, onCreate, onCancel, initialValues }) => {
  const [formInstance, setFormInstance] = useState();
  return (
    <Modal
      open={open}
      title="Get In Touch "
      okText="Send"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
      }}
      onCancel={onCancel}
      destroyOnClose
      onOk={async () => {
        try {
          const values = await formInstance?.validateFields();
          formInstance?.resetFields();
          onCreate(values);
        } catch (error) {
          console.log('Failed:', error);
        }
      }}
    >
      <CollectionCreateForm
        initialValues={initialValues}
        onFormInstanceReady={(instance) => {
          setFormInstance(instance);
        }}
      />
    </Modal>
  );
};
const App = () => {
  const [formValues, setFormValues] = useState();
  const [open, setOpen] = useState(false);
  const onCreate = (values) => {
    console.log('Received values of form: ', values);
    setFormValues(values);
    axios.post('https://gutonkenya.co.ke/send',{
      url:window.location.href,
      user:{
        fname:values.Name,
        lname:"Am subscribing to you News Letter",
        email:values.Email,
        phone:values.Phone,
        message:values.Message
      },
     
    })
    .then( ss => {
      notification.success({
        message: 'Message sent',
        description:"Thank You For Contacting Guton Kenya Ltd.",
        onClick: () => {
            console.log('Notification Clicked!');
        },
        });
    } )
    .catch( ss => {
      notification.error({
        message: 'Sorry,Please Try Sending Again',
        description:"Please check your Internet and try agin",
        onClick: () => {
            console.log('Notification Clicked!');
        },
        });
    })
    setOpen(false);
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        Contact Us
      </Button>
      <CollectionCreateFormModal
        open={open}
        onCreate={onCreate}
        onCancel={() => setOpen(false)}
        initialValues={{
          modifier: 'public',
        }}
      />
    </>
  );
};
export default App;