"use client"
import React from 'react'
import { Button, Carousel,Space,Typography } from 'antd';
import Image from 'next/image';
import FormInput from './formModal';
import Link from 'next/link';

const contentStyle = {
  margin: 0,
  height: '80vh',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center'
};

const Carousel_ = () => {

  return (
    <Carousel autoplay infinite arrows style={{width:'100%'}}>
     
  <div style={{width:'100%'}}>
  <div style={{width:'100%',height:"80vh",position:'relative'}}>
    <Image src="/11.jpg" loading='lazy' fill style={{objectFit:"cover"}} />
    <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',position:'absolute',top:0,height:'100%',width:'100%',background:'#969be27a',zIndex:2}} >
      <Typography.Title style={{color:'white',textAlign:'center'}}>Pioneering Real Estate Solutions for Kenya's Future </Typography.Title>
      <Typography.Text style={{color:'white',textAlign:'center'}}>Building Communities, Creating Opportunities, Transforming Lives </Typography.Text>
      <Space size="large" style={{marginTop:20}}>
        <Link href="about-guton-kenya-ltd">
        <Button type='primary'>
          About Us
        </Button>
       </Link>
       <FormInput />
      </Space>
    </div>
   </div>
  </div>
  <div style={{width:'100%'}}>
  <div style={{width:'100%',height:"80vh",position:'relative'}}>
    <Image src="/22.jpg" loading='lazy' fill style={{objectFit:"cover"}} />
    <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',position:'absolute',top:0,height:'100%',width:'100%',background:'#969be27a',zIndex:2}} >
      <Typography.Title style={{color:'white',textAlign:'center'}}>Guton's Commitment to Affordable Real Estate</Typography.Title>
      <Typography.Text style={{color:'white',textAlign:'center'}}>Your Partner in Property: Where Quality Meets Affordability</Typography.Text>
      <Space size="large" style={{marginTop:20}}>
		  <Link href="about-guton-kenya-ltd">
        <Button type='primary'>
          About Us
        </Button>
       </Link>
        <FormInput />
      </Space>
    </div>
   </div>
  </div>
  <div style={{width:'100%'}}>
  <div style={{width:'100%',height:"80vh",position:'relative'}}>
    <Image src="/team.jpg" loading='lazy' fill style={{objectFit:"cover"}} />
    <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',position:'absolute',top:0,height:'100%',width:'100%',background:'#969be27a',zIndex:2}} >
      <Typography.Title style={{color:'white',textAlign:'center'}}>Empowering Kenya's Growth </Typography.Title>
      <Typography.Text style={{color:'white',textAlign:'center'}}>From Land to Homes, We Make Your Dreams a Reality</Typography.Text>
      <Space size="large" style={{marginTop:20}}>
        <Link href="about-guton-kenya-ltd">
        <Button type='primary'>
          About Us
        </Button>
       </Link>
        <FormInput />
      </Space>
    </div>
   </div>
  </div>
  </Carousel>
  )
}

export default Carousel_