"use client"
import { AimOutlined, EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Divider, Image, Space, Typography } from 'antd';
import moment from 'moment';
import Link from 'next/link';
import FormInput from './formModal';

const { Meta } = Card;
const App = ({data}) => {
  console.log(data)
  return  (
   <div style={{width:'100%'}}>
     <Image    style={{width:'100%',objectFit:'cover',height:300}} alt={data.name}   src={`https://gutonkenya.co.ke${data.images[0]}`} />
    <div style={{display:'flex',alignItems:'center',gap:20,flexDirection:'column',marginTop:10}}>
     <Typography.Title level={4}>
        {data.name ? data.name : data.title}
     </Typography.Title>
     <Typography.Text>
       {data.price ? data.price : data.author}
     </Typography.Text>
     <Typography.Text>
       {data.location ? data.location : moment(data.created_at).fromNow()}
     </Typography.Text>
     <Space>
      <Link href={`${data.name ? `/property/${data.id}` : `/blog/${data.id}`}`}><Button >Read More</Button></Link>
      <FormInput />
     </Space>
    </div>
   </div>
  );
}
export default App;